import React from "react"

import Layout from "../../../components/layout-static"
import Seo from "../../../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Appeals Lawyer Connecticut",
    heroH1: "Appeals Lawyer Connecticut",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Connecticut appeals Lawyer</h2>
            <p className="mb-8">
              Appellate experience at our firm provides our clients with quality
              representation at all appellate levels. Bartlett Legal Group’s
              appellate attorneys appear in the Connecticut Appellate and
              Supreme Courts, and United States Courts of Appeals. Their
              appellate work covers all areas of personal injury litigation.{" "}
            </p>
            <p className="mb-8">
              Attorney Frank Bartlett has briefed and argued precedent-setting
              personal injury and workers’ compensation cases. The firm has made
              significant contributions to the law regarding the applicability
              of Connecticut’s motor vehicle exclusion to the workers’
              compensation exclusivity, dram shop liability and bar and
              restaurant owners’ duty to properly supervise their patrons to
              prevent fights.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
          <div className="mb-16">
            <h2>Appellate Lawyers</h2>
            <p className="mb-8">
              It takes special knowledge and ability to win appeals, especially
              at higher court levels. Our Appellate Lawyers have the in-depth
              experience and understanding needed to successfully challenge or
              defend rulings through appeals for our clients. Our attorneys
              possess the unique experience needed to handle appellate claims.
            </p>
            <p className="mb-8">
              If you have received an unfavorable result in your civil trial
              there may be legitimate grounds for appeal. Similarly, if the
              opponent to a successful trial seeks and appeal of the verdict you
              have won, we can help protect your judgement.
            </p>
            <p className="mb-8">
              Clients come from across Connecticut to work with our Appellate
              Attorneys to defend successful verdicts and seek to overturn
              erroneous rulings. Attorney Frank Bartlett, Jr. has utilized the
              knowledge he gained as a clerk to Judge Francis X. Hennessey of
              the Connecticut Appellate Court as well as years of arguing
              appeals as a special appellate public defender to advocate on
              behalf of his clients.
            </p>
            <p className="mb-8">
              If you have questions about your legal matters, an earlier court
              ruling or other legal concerns, please contact the law firm of
              Bartlett Legal Group, LLC, in Cheshire CT.
            </p>
            <p className="mb-8">
              09/20/2013 Attorney Bartlett successfully argues before the CT
              Supreme Court Wood v. Club LLC covered live on TV on the CT News
              Network. Attorney Bartlett starts his arguments 30 minutes and 16
              seconds into the video. To watch the video go to our home page and
              click on the photo under appellate lawyers in the lower portion of
              the home page.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
